import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useCCContext } from '../CC/CCContext';
import GenericIconButton from '../GenericComponents/GenericIconButton';
import genericStyles from '../GenericComponents/GenericStyles.module.css';
import * as ccUtils from '../CC/utils';
import styles from './Record.module.css';
import ZoomedImage from './ZoomedImage';

interface RecordProps {
  record: any;
}

interface Image {
  image: string;
  copyright: string;
  rank: string;
}

function replaceExtensionWithDzi(imageUrl: string): string {
  const urlWithoutExtension = imageUrl.substring(0, imageUrl.lastIndexOf('.'));
  return `${urlWithoutExtension}.dzi`;
}

function Record({ record }: RecordProps) {
  const { t, i18n } = useTranslation();
  const ctx = useCCContext();
  const filmStripRef = useRef<HTMLDivElement | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [noImageUrl, setNoImageUrl] = useState<string>('path_to_default_no_image.jpg'); // Set default no image URL as string
  const [filmStripImages, setFilmStripImages] = useState<Image[]>([]);
  const [error, setError] = useState<Error | null>(null);
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  useEffect(() => {
    if (record) {
      const images = Array.isArray(record?.data?.record?.image) ? record?.data?.record?.image : [record?.data?.record?.image];
      const sortedImages = images.filter(Boolean).sort((a: Image, b: Image) => parseInt(a.rank) - parseInt(b.rank)); // Filter out null or undefined images
      setFilmStripImages(sortedImages);
      setCurrentIndex(0);

      if (filmStripRef.current) {
        filmStripRef.current.scrollLeft = 0;
      }

      const defaultImageUrl = sortedImages.length > 0 ? `ccImageProxy.ashx?filename=images/${replaceExtensionWithDzi(sortedImages[0].image)}` : noImageUrl;
      setImageUrl(defaultImageUrl);
    }
  }, [record]);

  useEffect(() => {
    if (record?.data?.record?.title?.title) {
      document.title = `Collections du Centre des monuments nationaux - ${record?.data?.record?.title?.title}`;
    }
  
    return () => {
      document.title = "Collections du Centre des monuments nationaux";
      console.log('useEffect close', document.title);
    };
  }, [record]);


  if (!record) {
    return <div>No record selected.</div>;
  }

  function getHyperlinks(record: any): JSX.Element | null {
    const hyperlinks = record?.data?.record?.hyperlink;
  
    if (!hyperlinks) return null;
  
    const renderLink = (link: { objnrlink: string; hyperlink: string }) => {
      const linkText = link.objnrlink;
      return (
        <a 
          key={link.objnrlink} 
          href={link.hyperlink} 
          target="_blank" 
          rel="noopener noreferrer" 
          className={styles.tagValue}
        >
          {linkText}
        </a>
      );
    };
  
    return (
      <div className={styles.row}>
        <span className={styles.taglabel}>{t("record_hyperlink")} : </span>
        {Array.isArray(hyperlinks) 
          ? hyperlinks.map((link, index) => (
              <span key={link.objnrlink}>
                {renderLink(link)}
                {index < hyperlinks.length - 1 && ', '}
              </span>
            ))
          : renderLink(hyperlinks)}
      </div>
    );
  }

  function getMonumentWebsite(record: any): JSX.Element | null {
    const monumentWebsite = record?.data?.record?.monumentwebsite;
    if (!monumentWebsite) return null;

    const { hyperlinktextfr, hyperlinktexten, monumentwebsite: url } = monumentWebsite;
    const linkText = i18n.language === 'fr' ? hyperlinktextfr : hyperlinktexten;

    return (
      <div className={styles.row}>
        <span className={styles.taglabel}>{t("record_monument_website")} : </span>
        <a href={url} target="_blank" rel="noopener noreferrer" className={`${styles.tagValue} ${styles.tagValueHyperlink}`}>
          {linkText}
        </a>
      </div>
    );
  }

  const handleScrollLeft = () => {
    const filmStripElement = filmStripRef.current;
    filmStripElement && filmStripElement.scrollBy({
      left: -300,
      behavior: 'smooth'
    });
  };

  const handleFilmStripImageClick = (image: Image, idx: number) => {
    const newImageFilename = replaceExtensionWithDzi(image.image);
    const newImageUrl = `ccImageProxy.ashx?filename=images/${newImageFilename}`;
    setImageUrl(newImageUrl);
    setCurrentIndex(idx);
  };

  const handleScrollRight = () => {
    const filmStripElement = filmStripRef.current;
    filmStripElement && filmStripElement.scrollBy({
      left: 300,
      behavior: 'smooth'
    });
  };

  function getDefaultRow(record: any, label: string, fieldName: string, defaultValue: string = ""): JSX.Element | null {
    function getNestedValue(obj: any, path: string): any {
      return path.split('.').reduce((acc, part) => acc && acc[part], obj);
    }
  
    let fieldValue = getNestedValue(record?.data?.record, fieldName);
  
    if (Array.isArray(fieldValue)) {
      fieldValue = fieldValue.join(', ');
    } else if (typeof fieldValue === 'object' && fieldValue !== null) {
      fieldValue = JSON.stringify(fieldValue);
    }
  
    if (!fieldValue && !defaultValue) return null;
  
    return (
      <div className={styles.row}>
        <span className={styles.taglabel}>{label} : </span>
        <span 
          className={styles.tagValue}
          dangerouslySetInnerHTML={{ __html: fieldValue ?? defaultValue }}
        ></span>
      </div>
    );
  }
  
  function getDefaultRowHyperlink(record: any, label: string, fieldName: string, hyperlinkfield: string = "", defaultValue: string = ""): JSX.Element | null {
    function getNestedValue(obj: any, path: string): any {
      return path.split('.').reduce((acc, part) => acc && acc[part], obj);
    }

    console.log(fieldName);
    let fieldValue = getNestedValue(record?.data?.record, fieldName);
    console.log(fieldValue);

    const handleOnClick = (label: string, value: string, e: React.MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
      ccUtils.customSearch(ctx, '/record/' + hyperlinkfield, '', '' + value + '', label + ' : ' + value);
    };

    if (!fieldValue && !defaultValue) return null;

    return (
      <div className={styles.row}>
        <span className={styles.taglabel}>{label} : </span>
        {fieldValue
          ? <span className={styles.hyperlink} onClick={(e) => handleOnClick(label, fieldValue, e)}>{fieldValue}</span>
          : defaultValue}
      </div>
    );
  }

  function getPersonnes(record: any, tag: keyof typeof record.data.record) {
    const elements = record?.data?.record?.[tag];
    if (!elements) return null;

    const elementArray = Array.isArray(elements) ? elements : [elements];

    elementArray.sort((a, b) => {
      const keyA = a.roletypeid + ',' + a.displayorder;
      const keyB = b.roletypeid + ',' + b.displayorder;
      return keyA.localeCompare(keyB);
    });

    const handleOnClick = (e: React.MouseEvent, name: string) => {
      e.preventDefault();
      e.stopPropagation();
      ccUtils.customSearch(ctx, '/record/creator,/record/conxother', '', '[' + name + ']', 'Constituent : ' + name);
    };

    const rows = elementArray.map((element: any, index) => {
      const { role, [tag]: tagValue, DisplayDate } = element;
      return (
        <div className={styles.row} key={index}>
          <span className={styles.taglabel}>{role} : </span>
          <span className={styles.authName} onClick={(e) => handleOnClick(e, tagValue)}>{tagValue}</span>
          {DisplayDate && <span className={styles.authDate}>&nbsp;({DisplayDate})</span>}
        </div>
      );
    });

    if (rows.length === 0) return null;

    return <>
      <div style={{ marginTop: '5px', marginBottom: '5px' }}>
        {t("record_personnes_institutions")}
      </div>
      <div style={{ fontSize: '0.9em' }}>
        {rows}
      </div>
    </>
  }

  try {
    return (
      <div id="pdf-container" className={styles.Record}>
        <div className={`pdfOnly`} style={{ marginBottom: '20px' }}><img src='headerPdf.jpg' style={{ width: '300px' }} /></div>
        <div className={styles.ccListviewRecordImage}>
          <div className={styles.imgItemWrapper}>
            <span className={styles.imgItem + " " + styles.imgItemFirst}>
              <div className={styles.ZoomedImage}>
                {!imageUrl && noImageUrl && <img src={noImageUrl} alt="No image available" />}
                {imageUrl && <ZoomedImage key={imageUrl} dzi={imageUrl} downloadAllowed={true} images={filmStripImages.map(img => ({ imagesmore: img.image, copyright: img.copyright, rank: img.rank }))} initialIndex={currentIndex} />}
              </div>
            </span>
          </div>
          {filmStripImages.length > 1 && (
            <div className={styles.filmStripContainer}>
              <div className={styles.buttonContainer}>
                <GenericIconButton icon='light/angle-left.svg' width='22px' height='20px' onClick={handleScrollLeft} />
              </div>
              <div className={styles.filmStrip} ref={filmStripRef}>
                {filmStripImages.map((img, idx) => (
                  <img
                    key={idx}
                    src={`ccImageProxy.ashx?filename=images/${img.image}&height=100&bg=ffffff&cache=yes`}
                    alt={`Image rank ${img.rank}`}
                    className={styles.filmStripImage}
                    onClick={() => handleFilmStripImageClick(img, idx)}
                    //onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => { leads to infinite loop
                    //  e.currentTarget.onerror = null;
                    //  e.currentTarget.src = noImageUrl || 'path_to_default_no_image.jpg'; // Ensure string fallback
                    //}}
                  />
                ))}
              </div>
              <div className={styles.buttonContainer}>
                <GenericIconButton icon='light/angle-right.svg' width='22px' height='20px' onClick={handleScrollRight} />
              </div>
            </div>
          )}
        </div>

        <div className={styles.ccListviewRecordText}>
          <div className={styles.title}>
            {getDefaultRow(record, t("record_title"), "title.title")}
          </div>
          {getDefaultRow(record, t("record_objectnumber"), "objectnumber")}
          {getDefaultRowHyperlink(record, t("record_collection"), "webcollection", 'webcollection')}
          {getDefaultRowHyperlink(record, t("record_auteur"), "auteur.auteur", "auteur")}
          {getPersonnes(record, 'conxother')}
          {getHyperlinks(record)}
          {getDefaultRow(record, t("record_objectname"), "objectname")}
          {getDefaultRow(record, t("record_dated"), "dated")}
          {getDefaultRowHyperlink(record, t("record_periode"), "period", "period")}
          {getDefaultRow(record, t("record_medium"), "medium")}
          {getDefaultRow(record, t("record_dimensions"), "dimensions")}
          {getDefaultRow(record, t("record_description"), "description")}
          {getDefaultRow(record, t("record_inscribed"), "inscribed")}
          {getDefaultRow(record, t("record_portfolio"), "portfolio")}
          {getDefaultRow(record, t("record_provenance"), "provenance")}
          {getDefaultRow(record, t("record_related"), "relatedworks")}
          {getDefaultRow(record, t("record_exhibitions"), "exhibitions")}
          {getDefaultRow(record, t("record_objectstatus"), "objectstatus")}
          {getDefaultRow(record, t("record_accessionmethod"), "accessionmethod")}
          {getDefaultRow(record, t("record_accessiondate"), "accessiondate")}
          {getDefaultRow(record, t("record_bibliography"), "bibliography")}
          {getDefaultRow(record, t("record_location"), "location")}
          {getDefaultRow(record, t("record_protection"), "protection")}          
          {getMonumentWebsite(record)}
          {
             //link bij tappiserie records
             //A propos des tapisseries
             //<a href="https://collection.tapisseries.monuments-nationaux.fr" target="_blank" rel="noopener noreferrer">{t('footer_tapestries_collection')} &gt;</a>
          }

        </div>
      </div>
    );
  } catch (e) {
    setError(e as any);
    return <div>Error: {(e as any).message}</div>;
  }
}

export default Record;
