import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './RecordList.module.css';
import ImageWithFallback from '../GenericComponents/ImageWithFallback';
import * as ccUtils from '../CC/utils';
import { useCCContext } from '../CC/CCContext';

interface RecordListProps {
    record: any;
    index: number;
    handleLinkClick: (event: React.MouseEvent, index: number) => void;
}

const RecordList: React.FC<RecordListProps> = ({ record, index, handleLinkClick }) => {
    const { t } = useTranslation();
    const ctx = useCCContext();

    function getDefaultRow(record: any, label: string, fieldName: string, defaultValue: string = ""): JSX.Element | null {
        function getNestedValue(obj: any, path: string): any {
            return path.split('.').reduce((acc, part) => acc && acc[part], obj);
        }

        let fieldValue = getNestedValue(record?.data?.record, fieldName);

        //console.log(fieldName, fieldValue); // Add this line to log the value

        if (typeof fieldValue === 'object' && fieldValue !== null) {
            console.warn(`Rendering object as string for field ${fieldName}:`, fieldValue);
            fieldValue = JSON.stringify(fieldValue);
        }

        if (!fieldValue && !defaultValue) return null;

        return (
            <div className={styles.row}>
                <span className={styles.taglabel}>{label} : </span>
                <span className={styles.tagValue}>{fieldValue ?? defaultValue}</span>
            </div>
        );
    }

    function getDefaultRowHyperlink(record: any, label: string, fieldName: string, hyperlinkfield: string = "", defaultValue: string = ""): JSX.Element | null {
        function getNestedValue(obj: any, path: string): any {
            return path.split('.').reduce((acc, part) => acc && acc[part], obj);
        }

        let fieldValue = getNestedValue(record?.data?.record, fieldName);

        const handleOnClick = (label: string, value: string, e: React.MouseEvent) => {
            e.stopPropagation();
            e.preventDefault();
            ccUtils.customSearch(ctx, '/record/' + hyperlinkfield, '', '' + value + '', label + ' : ' + value);
        };

        // If fieldValue doesn't exist and no defaultValue is provided, return null
        if (!fieldValue && !defaultValue) return null;

        return (
            <div className={styles.row}>
                <span className={styles.taglabel}>{label} : </span>
                {fieldValue
                    ? <span className={styles.hyperlink} onClick={(e) => handleOnClick(label, fieldValue, e)}>{fieldValue}</span>
                    : defaultValue}
            </div>
        );
    }


    function getThesaurusRows(record: any, label: string, CN: string, minLength: number) {
        const thesTerms = record?.data?.record?.ThesTerm;

        if (!thesTerms) return null;

        // Filter the terms based on the provided CN
        const terms = Array.isArray(thesTerms) ?
            thesTerms.filter((term: any) => term.CN.startsWith(CN)) :
            [thesTerms].filter((term: any) => term.CN.startsWith(CN));

        const rows = terms.map((term: any) => {
            let termElements: JSX.Element[] = [];
            let firstDone = false;

            term.TermPathPart.forEach((part: any) => {
                if (part.CN && part.CN.length >= minLength) {
                    termElements.push(
                        <span>
                            {firstDone && <span className={styles.thesNodeSeperator}>&gt;</span>}
                            <span className={styles.thesNode} onClick={(e) => handleThesaurusNodeClick(e, part.CN, label, part.TermPathPart)}>
                                {part.TermPathPart}
                            </span>
                        </span>
                    );
                    firstDone = true;
                }
            });

            return (
                <div className={styles.row} key={term.CN}>
                    <span className={styles.taglabel}>{label} : </span>
                    {termElements}
                </div>
            );
        });

        if (rows.length === 0) return null;

        return rows;
    }

    function getThesaurusRowsByType(record: any, label: string, ThesType: string, minLength: number) {
        //console.log(record);
        const thesTerms = record?.data?.record?.mqb_thesterm;

        if (!thesTerms) return null;

        // Filter the terms based on the provided ThesType
        const terms = Array.isArray(thesTerms) ?
            thesTerms.filter((term: any) => term.Type.trim() === ThesType) :
            [thesTerms].filter((term: any) => term.Type.trim() === ThesType);

        const rows = terms.map((term: any, index) => {
            let termElements: JSX.Element[] = [];
            let firstDone = false;

            term.thesTerms.forEach((part: any, index: number) => {
                if (part.CN && part.CN.length >= minLength) {
                    termElements.push(
                        <span key={index}>
                            {firstDone && <span className={styles.thesNodeSeperator}>&gt;</span>}
                            <span className={styles.thesNode} onClick={(e) => handleThesaurusNodeClick(e, part.CN, label, part.TERM)}>
                                {part.TERM}
                            </span>
                        </span>
                    );
                    firstDone = true;
                }
            });

            return (
                <div className={styles.row} key={index}>
                    <span className={styles.taglabel}>{label} : </span>
                    {termElements}
                </div>
            );
        });

        if (rows.length === 0) return null;

        return rows;
    }


    function handleThesaurusNodeClick(event: React.MouseEvent, CN: string, label: string, term: string): void {
        event.stopPropagation();
        event.preventDefault();
        ccUtils.customSearch(ctx, '/Record/mqb_thesterm/thesTerms/CN', '', '[' + CN + ']', label + ': ' + term);
        //console.log(CN, term);
    }

    function hasFilterValue(record: any, filterField: string, filterValue: string): boolean {
        const fieldData = record?.data?.record?.[filterField];

        if (!fieldData) return false;

        // If fieldData is an array
        if (Array.isArray(fieldData)) {
            return fieldData.includes(filterValue);
        }

        // If fieldData is a single value

        return fieldData === filterValue;
    }

    function getPersonnes(record: any, tag: keyof typeof record.data.record) {
        const elements = record?.data?.record?.[tag];
        if (!elements) return null;

        // Convert to array if not already
        const elementArray = Array.isArray(elements) ? elements : [elements];

        // Sorting logic (if required)
        elementArray.sort((a, b) => {
            const keyA = a.roletypeid + ',' + a.displayorder;
            const keyB = b.roletypeid + ',' + b.displayorder;
            return keyA.localeCompare(keyB);
        });

        const handleOnClick = (e: React.MouseEvent, name: string) => {
            e.preventDefault();
            e.stopPropagation();
            ccUtils.customSearch(ctx, '/record/creator,/record/conxother', '', '[' + name + ']', 'Constituent : ' + name);
        };

        const rows = elementArray.map((element: any, index) => {
            const { role, [tag]: tagValue, DisplayDate } = element;
            return (
                <div className={styles.row} key={index}>
                    <span className={styles.taglabel}>{role} : </span>
                    <span className={styles.authName} onClick={(e) => handleOnClick(e, tagValue)}>{tagValue}</span>
                    {DisplayDate && <span className={styles.authDate}>&nbsp;({DisplayDate})</span>}
                </div>
            );
        });

        if (rows.length === 0) return null;

        return <>
            <div>
                {t("record_personnes_institutions")}
            </div>
            <div>
                {rows}
            </div>
        </>
    }

    //ccImageProxy.ashx?filename=images/020/Images%20pleine%20page/AUL1959000097_001.JPG&width=180&_height=160&borderwidth=0&borderheight=0&bordercolor=e8e8e8&bg=f8f8f8&passepartoutwidth=0&passepartoutheight=0&passepartoutcolor=f8f8f8&cache=yes
    // console.log(record);
    let filename = "";
    if (record?.data?.record?.image) {
        if (Array.isArray(record?.data?.record?.image)) {
            if (record?.data?.record?.image.length > 0) {
                const imageWithLowestRank = record.data.record.image.reduce((prev: any, curr: any) => {
                    return parseInt(prev.rank) < parseInt(curr.rank) ? prev : curr;
                });
                filename = imageWithLowestRank.image;
            }
        } else {
            filename = record.data.record.image.image;
        }
    }      

    // console.log(filename);

    const imageUrl = filename ?
        `ccImageProxy.ashx?filename=images/${filename}&width=180&_height=160&borderwidth=0&borderheight=0&bordercolor=e8e8e8&bg=f8f8f8&passepartoutwidth=0&passepartoutheight=0&passepartoutcolor=f8f8f8&cache=yes` :
        `ccImageProxy.ashx?filename=images/noimage.cci&width=180&_height=160&borderwidth=0&borderheight=0&bordercolor=e8e8e8&bg=f8f8f8&passepartoutwidth=0&passepartoutheight=0&passepartoutcolor=f8f8f8&cache=yes`;

    //console.log(imageUrl);
    const invNo = record?.data?.record?.ObjectNumber;
    const recordDataAsString = JSON.stringify(record?.data?.record, null, 2); // Converts the object to a JSON string with pretty print

    return (
        <div>
            {false && <textarea value={recordDataAsString}></textarea>}

            <a
                href={`${window.location.pathname}?action=search&field=/Record/ObjectNumber&label=N°%20de%20gestion&value=${invNo}`}
                className={styles.RecordList}
                key={index}
                onClick={(event) => handleLinkClick(event, index)}
                onMouseDown={e => e.preventDefault()}
            >
                <div className={styles.listViewPanel}>
                    <div className={styles.ccListviewRecordTable}>
                        <div className={styles.ccListviewRecordImage}>
                            { /* https://collections.quaibranly.fr/cc/imageproxy.ashx?server=localhost&port=15012&filename=Objets\Images\nondisponible.cci&width=220&borderwidth=0&borderheight=0&bordercolor=000000&bg=ffffff&passepartoutwidth=0&passepartoutheight=0&passepartoutcolor=ff6666&cache=yes */}
                            {imageUrl && <ImageWithFallback src={imageUrl} alt="Image" placeholder="ccImageProxy.ashx?filename=images/noimage.cci&width=180" />}
                        </div>
                        <div className={styles.ccListviewRecordText}>

                            {getDefaultRow(record, t("record_title"), "title.title")}
                            {getDefaultRowHyperlink(record, t("record_auteur"), "auteur.auteur", "auteur")}
                            {getDefaultRow(record, t("record_dated"), "dated")}
                            {getDefaultRow(record, t("record_objectnumber"), "objectnumber")}
                            {getDefaultRow(record, t("record_collection"), "webcollection")}

                            {getPersonnes(record, 'conxother')}

                        </div>
                    </div>
                </div>
            </a>
        </div>
    );
}

export default RecordList;
